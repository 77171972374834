<template>
  <v-container fluid align-center class="pt-15">
    <v-row class="mt-5 mx-5">
      <v-col cols="12">
        <v-btn id="medidasdereduccionButton" @click="scrollToMedidas" x-small color="white" class="elevation-0 white--text">Hola</v-btn>
        <v-card >
          <v-card-title class="align-center justify-center " >
            <v-row justify="center" no-gutters align="center">

              <v-col cols="4" class="text-center">
                <v-card-title>Filtrar por:</v-card-title>
              </v-col>
              <v-col cols="4">
<!--                <span class="ml-5 mr-2">Tipo de periodo</span>-->
                <v-select v-model="record.tipoPeriodo" hint="Tipo de periodo" :items="catalogues.tipos_periodos" item-text="text" item-value="value"
                          prepend-icon="mdi-calendar-check" dense class="ml-5 mr-2 mt-5  filtrosPanel" style="max-width: 290px;" :disabled="loading" @change="setDateRecord()"></v-select>
              </v-col>
              <v-col cols="4">
<!--                <span class="ml-5 mr-1">periodo</span>-->
                <template v-if="record.tipoPeriodo === 'mensual'">
                  <v-menu ref="menuRecord" v-model="record.menu" :close-on-content-click="false" :return-value.sync="record.periodo"
                          transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="record.periodo" v-bind="attrs" dense v-on="on" prepend-icon="mdi-calendar" class="mx-2 mt-5" style="max-width: 190px;" :disabled="loading" readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="record.periodo" type="month" locale="es" style="background-color: #f1f1f1">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="record.menu = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="success" @click="saveDateFilter(record.periodo)">
<!--                      <v-btn text color="success" @click="$refs.menuRecord.save(record.periodo); getData()">-->
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
                <template v-else-if="record.tipoPeriodo === 'anual'">
                  <v-select v-model="record.periodo" :items="catalogues.years" item-text="text" item-value="value"
                            prepend-icon="mdi-calendar" dense class="mx-2" style="max-width: 190px;" light hide-details :disabled="loading" @change="getData()"></v-select>
                </template>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="mx-5">


      <v-card-text v-show="!loading">
        <v-row no-gutters justify="space-between">
          <div class="card-container">
            <v-card id="card-cards"  min-height="200" min-width="300" max-width="320" >
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Comparado con la industria
                </v-spacer>

              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;">
                <v-spacer>
                  {{kpis.comparado_industria.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.comparado_industria.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pt-0">
                Promedio: {{kpis.comparado_industria.promedio}} tCO2e
              </v-card-text>
            </v-card>
          </div>
          <div class="card-container card-right">
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipTwo" @click="$refs.tooltipGuide.start(1)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="font-size: 17px; color: black;">
                <v-spacer>
                  Comparado con tu objetivo
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                <v-spacer v-if="kpis.comparado_objetivo.valor">
                  {{kpis.comparado_objetivo.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.comparado_objetivo.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Objetivo: {{kpis.comparado_objetivo.objetivo}} tCO2e
              </v-card-text>
            </v-card>
          </div>
          <div class="card-container">
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320" >
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipThree" @click="$refs.tooltipGuide.start(2)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Consumo energético
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                <v-spacer>
                  {{kpis.consumo_energetico.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.consumo_energetico.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Pasado: {{kpis.consumo_energetico.consumo_pasado}} kWh
              </v-card-text>
            </v-card>
          </div>
          <div class="card-container card-right">
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
              <v-sheet class="text-center tooltipFour" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipFour" @click="$refs.tooltipGuide.start(3)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Compensación
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(69 73 215); font-weight: bold;" >
                <v-spacer>
                  <a href="https://planetacarbononeutral.org/elige-cantidad-a-compensar/" target="_blank" style="text-decoration: none">
                    {{kpis.compensaciones.valor}}
                  </a>
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                tCO2e
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Equivalente: {{kpis.compensaciones.equivalencia}} árboles
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-card-text>

      <!--
      <v-card-text v-show="!loading">
        <v-row no-gutters justify="space-between">
          <div>
            <v-card id="card-cards"  min-height="200" min-width="300" max-width="320" >
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Comparado con la industria
                </v-spacer>

              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;">
                <v-spacer>
                  {{kpis.comparado_industria.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.comparado_industria.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pt-0">
                Promedio: {{kpis.comparado_industria.promedio}} tCO2e
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipTwo" @click="$refs.tooltipGuide.start(1)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="font-size: 17px; color: black;">
                <v-spacer>
                  Comparado con tu objetivo
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                <v-spacer v-if="kpis.comparado_objetivo.valor">
                  {{kpis.comparado_objetivo.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.comparado_objetivo.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Objetivo: {{kpis.comparado_objetivo.objetivo}} tCO2e
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320" >
              <v-sheet class="text-center" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipThree" @click="$refs.tooltipGuide.start(2)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Consumo energético
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                <v-spacer>
                  {{kpis.consumo_energetico.valor}}
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                {{kpis.consumo_energetico.subtitulo}}
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Pasado: {{kpis.consumo_energetico.consumo_pasado}} kWh
              </v-card-text>
            </v-card>
          </div>
          <div>
            <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
              <v-sheet class="text-center tooltipFour" style="position: relative;">
                <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipFour" @click="$refs.tooltipGuide.start(3)">
                  <v-icon small>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </v-sheet>
              <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                <v-spacer>
                  Compensación
                </v-spacer>
              </v-card-title>
              <v-card-title style="font-size: 45px; text-align: center; color: rgb(69 73 215); font-weight: bold;" >
                <v-spacer>
                  <a href="https://planetacarbononeutral.org/elige-cantidad-a-compensar/" target="_blank" style="text-decoration: none">
                    {{kpis.compensaciones.valor}}
                  </a>
                </v-spacer>
              </v-card-title>
              <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                tCO2e
              </v-card-text>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                Equivalente: {{kpis.compensaciones.equivalencia}} árboles
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-card-text>
      -->
      <!--
      <v-col v-show="!loading" cols="12">
        <v-card min-height="250" min-width="300" class="pa-5 elevation-0 transparent">

          <v-row >
            <v-col cols="12" sm="6" lg="3" class="card2" >
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320" >
                <v-sheet class="text-center" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipOne" @click="$refs.tooltipGuide.start(0)">
                    <v-icon small color="#2F9975">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                      <v-spacer>
                        Comparado con la industria
                      </v-spacer>

                </v-card-title>
                <v-card-title style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;">
                  <v-spacer>
                    {{kpis.comparado_industria.valor}}
                  </v-spacer>
                </v-card-title>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.comparado_industria.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pt-0">
                  Promedio: {{kpis.comparado_industria.promedio}} tCO2e
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card2" >
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-sheet class="text-center" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipTwo" @click="$refs.tooltipGuide.start(1)">
                    <v-icon small>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="text-center font-weight-bold" style="font-size: 17px; color: black;">
                  <v-spacer>
                  Comparado con tu objetivo
                  </v-spacer>
                </v-card-title>
                <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                  <v-spacer v-if="kpis.comparado_objetivo.valor">
                    {{kpis.comparado_objetivo.valor}}
                  </v-spacer>
                </v-card-title>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.comparado_objetivo.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Objetivo: {{kpis.comparado_objetivo.objetivo}} tCO2e
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card2" >
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320" >
                <v-sheet class="text-center" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipThree" @click="$refs.tooltipGuide.start(2)">
                    <v-icon >
                      mdi-information-variant
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                  <v-spacer>
                    Consumo energético
                  </v-spacer>
                </v-card-title>
                <v-card-title style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;">
                  <v-spacer>
                    {{kpis.consumo_energetico.valor}}
                  </v-spacer>
                </v-card-title>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.consumo_energetico.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Pasado: {{kpis.consumo_energetico.consumo_pasado}} kWh
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card2" >
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-sheet class="text-center tooltipFour" style="position: relative;">
                  <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipFour" @click="$refs.tooltipGuide.start(3)">
                    <v-icon color="#2F9975" >
                      mdi-information
                    </v-icon>
                  </v-btn>
                </v-sheet>
                <v-card-title class="text-center font-weight-bold" style="color: black; font-size: 17px">
                  <v-spacer>
                    Compensación
                  </v-spacer>
                </v-card-title>
                <v-card-title style="font-size: 45px; text-align: center; color: rgb(69 73 215); font-weight: bold;" >
                  <v-spacer>
                    <a href="https://planetacarbononeutral.org/elige-cantidad-a-compensar/" target="_blank" style="text-decoration: none">
                      {{kpis.compensaciones.valor}}
                    </a>
                  </v-spacer>
                </v-card-title>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  tCO2e
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Equivalente: {{kpis.compensaciones.equivalencia}} árboles
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


        </v-card>
      </v-col>
      -->
      <v-col v-show="loading" cols="12">
        <v-card>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">
            Huella de Carbono {{hdc.empresa}}
          </v-card-title>
          <v-row>
            <v-col>
              <div class="pt-12 pb-5">
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(137 60 219); font-weight: bold;" class="pt-15">
                  {{hdc.total}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  tCO2e
                </v-card-text>
              </div>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 12px;" class="pt-10">
                {{hdc.subtitulo}}
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-title>Alcance 1</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(69 73 215); font-weight: bold;" class="pt-2">
                {{hdc.alcance1}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 2</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(26 108 44); font-weight: bold;" class="pt-2">
                {{hdc.alcance2}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 3</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(185 77 44); font-weight: bold;" class="pt-2">
                {{hdc.alcance3}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Emisiones por categoría</v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.Doughnut.data" :options="chartData.Doughnut.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-sheet class="text-center tooltipFive" style="position: relative;">
            <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipFour" @click="$refs.tooltipGuide.start(4)">
              <v-icon small>
                mdi-information-outline
              </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class="justify-center font-weight-bold">Emisiones a través del tiempo</v-card-title>
          <div class="container medidasdereduccionOne">
            <Bar :chartData="chartData.Bar.data" :options="chartData.Bar.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!loading" cols="12" md="6" id="medidasdereduccionId">
        <v-card max-height="500" min-width="300" height="100%"
        style="display: flex !important; flex-direction: column;" >
          <v-sheet class="text-center tooltipSix" style="position: relative;">
            <v-btn icon style="position: absolute; top: 0; right: 0;" small class="tooltipFour" @click="$refs.tooltipGuide.start(5)">
              <v-icon small>
                mdi-information-outline
              </v-icon>
            </v-btn>
          </v-sheet>
          <v-card-title class=" justify-center font-weight-bold">Medidas de reducción</v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto;">
            <v-list-item-group v-model="selectReductionMeasure" active-class="success--text">
              <template v-for="(item, idx) in reductionMeasures">
                <v-list-item :key="idx" @click="showDialog(item)">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.equipo"></v-list-item-title>
                       <v-list-item-subtitle v-if="item.energia" class="text--primary" v-text="item.energia.join(', ')"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.sugerencia"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="success">
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= reductionMeasures.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="520" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Huella de Carbono por Instalación</v-card-title>
          <v-select v-model="record.locations" label="Ubicaciones" multiple :items="catalogues.locations" item-text="text" item-value="value"
           prepend-icon="mdi-domain" dense class="mr-7" style="max-width: 290px; margin-left: auto;" :disabled="loading" @change="updateFilteredDataEmisionesLocation()"></v-select>
          <div class="container" style="height: 77%;">
            <BarHorizontal ref="emisionesLocationChart" :chartData="chartData.BarHorizontal.filtered_data" :options="chartData.BarHorizontal.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="520" min-width="300" height="100%"
        style="display: flex !important; flex-direction: column;">
          <v-card-title class="justify-center font-weight-bold">Medidas de reducción</v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto;">
            <v-list-item-group v-model="selectReductionMeasure" active-class="success--text">
              <template v-for="(item, idx) in reductionMeasures">
                <v-list-item :key="idx" @click="dialogs.reductionMeasures = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.equipo"></v-list-item-title>
                       <v-list-item-subtitle v-if="item.energia" class="text--primary" v-text="item.energia.join(', ')"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.sugerencia"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="success">
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= reductionMeasures.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-overlay absolute :value="record.loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Generado reporte...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.reductionMeasures" persistent max-width="300">
      <v-card style="margin-top: 0; background-color: white;">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text class="text-wrap" v-if="!dialogs.selectItem.comprometido">¿Realmente desea establecer el compromiso?</v-card-text>
        <v-card-text class="text-wrap" v-else>¿Realmente desea eliminar el compromiso?</v-card-text>
        <v-card-actions v-if="dialogs.selectItem.comprometido">
          <v-spacer></v-spacer>
          <v-btn  text small :loading="loading" color="primary" @click="dialogs.reductionMeasures=false">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="red" @click="cancelNotification()">
            Eliminar
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn  text small :loading="loading" color="primary" @click="dialogs.reductionMeasures=false">
            Cancelar
          </v-btn>
          <v-btn  text small :loading="loading" color="success" @click="cancelNotification()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TourGuide ref="homeGuideStandar" :steps="tourStept"/>
    <CustomCardTooltip ref="tooltipGuide" :tooltips="tooltips" />
    <v-snackbar v-model="notData" color="info" :timeout="60000" bottom right :multi-line="true">
      No encontramos datos de este mes, vamos a retroceder al anterior {{record.periodo}}.
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="notData = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import moment from 'moment-timezone'
import Bar from '../Charts/Bar.js'
import BarHorizontal from '../Charts/BarHorizontal.js'
import Doughnut from '../Charts/Donut.js'
import downloadjs from 'downloadjs'
import _ from 'lodash'
import TourGuide from "@/components/TourGuide.vue";
import CustomTooltip from "@/components/CustomTooltip.vue";
import CustomCardTooltip from "@/components/CustomCardTooltip.vue";
import {TooltipsOne} from "@/utils/CatalogTooltips";

export default {
  name: 'dashboardStandard',
  components: {CustomCardTooltip, CustomTooltip, TourGuide, Bar, BarHorizontal, Doughnut },
  data: () => ({
    absolute:true,
    panel:null,
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    errors: {
      kpis: false,
      hdc: false,
      emissionByCategory: false,
      emissionOverTime: false,
      reductionMeasures: false
    },
    msgError: '',
    catalogues: {
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      years: [],
      locations: []
    },
    record: {
      loading: false,
      tipoPeriodo: 'mensual',
      periodo: '',
      menu: false,
      locations: [],
    },
    kpis: {
      comparado_industria: {
        promedio: '',
        subtitulo: '',
        valor: ''
      },
      comparado_objetivo: {
        objetivo: '',
        subtitulo: '',
        valor: ''
      },
      compensaciones: {
        equivalencia: '',
        valor: ''
      },
      consumo_energetico: {
        consumo_pasado: '',
        subtitulo: '',
        valor: ''
      }
    },
    hdc: {
      alcance1: '',
      alcance2: '',
      alcance3: '',
      empresa: '',
      subtitulo: '',
      total: ''
    },
    chartData: {
      Bar: {
        data: null,
        options: null
      },
      BarHorizontal: {
        data: null,
        filtered_data: null,
        options: null
      },
      Doughnut: {
        data: null,
        options: null
      }
    },
    reductionMeasures: [
      "Cómputo",
      "Panel solar",
      "Aire acondicionado",
      "Iluminación"
    ],
    selectReductionMeasure: null,
    isAuditor: false,
    isAgriculturalSector: false,
    dialogs: {
      reductionMeasures: false,
      selectItem:{
        idCompany:null,
        comprometido:false
      }
    },
    tourStept:[
      {
        selector: '.medidasdereduccionOne',
        title: '',
        description: '<center>Elige las medidas que te gustaría aplicar.</center>',
        position: 'bottom',
        action: 'finish'
      }
    ],
    tooltips: TooltipsOne,
    notData:false,
    objReduction:null
  }),
  //Eje = Toneladas
  //Azul = Residuos generaos
  //Morado = Compras - Material comprado
  mounted() {
    this.setCatYears()
    this.setDateRecord()
    //this.checkGuia()
  },
  created() {
    // let fechastorage = JSON.parse(localStorage.getItem('filters'));
    // if (fechastorage){
    //   this.record.tipoPeriodo=fechastorage.tipoPeriodo
    //   this.record.periodo = fechastorage.periodo
    // }
    this.panel=0
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    typePeriod() {
      return _.capitalize(this.record.tipoPeriodo)
    },
    report() {
      return this.$store.state.general.report
    }
  },
  watch: {
    company: function (val) {
      this.getData()
    },
    report: function (val) {
      if (val) {
        this.getReportGeneral()
      }
    }
  },
  methods: {
    ...mapActions('general', [
      'setReport'
    ]),
    toggleTooltip() {
      this.valOne = !this.valOne;
    },
    scrollToMedidas(){
      const element = document.getElementById('medidasdereduccionId');
      if (element) {
        console.log('Desplazándose a Medidas de reducción', element);
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    checkGuia(){
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (tour.one && tour.two && !tour.three) {
        setTimeout(() => {
          const button = document.getElementById('medidasdereduccionButton');
          if (button) {
            button.click();
          }
          //homeGuideStandar
           this.$refs.homeGuideStandar.start();
        }, 1000);
      }

    },
    setCatYears() {
      let year = parseInt(moment().format('YYYY'))
      console.log("anio",year)
      for (let i = 0; i < 5; i++) {
        let dato = this.catalogues.years.unshift((year - i).toString())
        console.log("imprimir",dato)
      }
    },

    setDateRecord() {
      // let filters = JSON.parse(localStorage.getItem('filters'));
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM')
      }
      else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY')
      }

      let filters = JSON.parse(localStorage.getItem('filters'));
      if (filters){
          if (filters.tipoPeriodo === 'mensual'){
            this.record.tipoPeriodo = filters.tipoPeriodo
            this.record.periodo = filters.periodo
          }
      }
        // if (!item){
        //
        //   if(filters.tipoPeriodo){
        //     this.record.tipoPeriodo = filters.tipoPeriodo
        //     this.record.periodo = filters.periodo
        //     // await this.getData()
        //   }
        // }
        this.getData()

    },
    updateFilteredDataEmisionesLocation() {
      this.chartData.BarHorizontal.filtered_data.labels = JSON.parse(JSON.stringify(this.chartData.BarHorizontal.data.labels))
      this.chartData.BarHorizontal.filtered_data.datasets[0].data = JSON.parse(JSON.stringify(this.chartData.BarHorizontal.data.datasets[0].data))
      console.log('[LOG] record: ', this.chartData.BarHorizontal.filtered_data, this.chartData.BarHorizontal.data)

      if(this.record.locations.length > 0){
        this.chartData.BarHorizontal.filtered_data.labels.forEach(loc => {
          if(!this.record.locations.includes(loc)){
            const loc_idx = this.chartData.BarHorizontal.filtered_data.labels.indexOf(loc)
            if(loc_idx != -1){
              this.chartData.BarHorizontal.filtered_data.labels.splice(loc_idx, 1)
              this.chartData.BarHorizontal.filtered_data.datasets[0].data.splice(loc_idx, 1)
            }
          }
        });
        console.log('[LOG] record: ', this.record.locations, this.chartData.BarHorizontal.filtered_data)
      }

      this.$refs.emisionesLocationChart.renderChart(this.chartData.BarHorizontal.filtered_data, this.chartData.BarHorizontal.options);
    },
    getKpis() {
      this.loading = true
      this.axios.get(services.routes.kpis + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.kpis = response.data.data
          // console.log('Este es la chida',response.data.data)
        })
        .catch(error => {
          this.kpis = {
            comparado_industria: {
              valor: '-',
              subtitulo: 'Información no disponible',
              promedio: 0
            },
            comparado_objetivo: {
              valor: '-',
              subtitulo: 'Información no disponible',
              objetivo: 0
            },
            consumo_energetico: {
              valor: '-',
              subtitulo: 'Información no disponible',
              consumo_pasado: 0
            },
            compensaciones: {
              valor: 0,
              equivalencia: 0
            }
          }
          this.error = true
          this.errors.kpis = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getHDC() {
      this.loading = true

      await this.axios.get(services.routes.hdc + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.hdc = response.data.data
          this.hdc.empresa = 'de ' + this.hdc.empresa
        })
        .catch(error => {
          this.hdc = {
            empresa: '',
            total: 0,
            alcance1: 0,
            alcance2: 0,
            alcance3: 0,
            subtitulo: 'No existe información de huella de carbono en el año pasado para el mismo periodo'
          }
          this.error = true
          this.errors.hdc = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
          let filters = JSON.parse(localStorage.getItem('filters'));
          if(!filters){
            if(this.hdc.total==0){
              if(this.record.tipoPeriodo=='mensual'){
                const menosunmes = moment(this.record.periodo).subtract(1, 'months').format('YYYY-MM')
                console.log("Retroceder a:", menosunmes)
                this.record.periodo = menosunmes
                this.getKpis()
                this.getHDC()
                this.getEmissionByCategory()
                this.getEmissionOverTime()
                this.getReductionMeasures()
                this.getRole()
                this.getProfessionalSector()
                this.notData=true
              }

            }
          }else{
            console.log('No Retroceder')

          }
          //   console.log("Entro aqui")
          //   if(this.hdc.total==0){
          //     const menosunmes = moment(this.record.periodo).subtract(1, 'months').format('YYYY-MM')
          //     console.log("Retroceder a:", menosunmes)
          //     this.record.periodo = menosunmes
          //     this.getKpis()
          //     this.getHDC()
          //     this.getEmissionByCategory()
          //     this.getEmissionOverTime()
          //     this.getReductionMeasures()
          //     this.getRole()
          //     this.getProfessionalSector()
          //     this.notData=true
          //   }
          // }


        })
    },
    getEmissionByCategory() {
      this.loading = true
      this.axios.get(services.routes.emissionByCategory + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.chartData.Doughnut.data = response.data.data
          this.chartData.Doughnut.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 20,
                padding: 15,
                fontSize: 12,
                fontFamily: 'Arial, sans-serif',
                // fontFamily: 'Quicksand'
              }
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var total = dataset.data.reduce(function(previousValue, currentValue) {
                    return previousValue + currentValue;
                  });
                  var currentValue = dataset.data[tooltipItem.index];
                  //var percentage = Math.floor(((currentValue / total) * 100)+0.5);
                  return currentValue + "%";
                }
              }
            },
          }
        })
        .catch(error => {
          this.chartData.Doughnut.data = {
            labels: [],
            datasets: [
              {
                data: []
              }
            ]
          }
          this.chartData.Doughnut.options =  {
            responsive: true,
            maintainAspectRatio: false
          }
          this.error = true
          this.errors.emissionByCategory = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEmissionLocation() {
      this.loading = true
      this.axios.get(services.routes.emissionLocation + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.chartData.BarHorizontal.data = JSON.parse(JSON.stringify(response.data.data))
          this.chartData.BarHorizontal.filtered_data = JSON.parse(JSON.stringify(response.data.data))
          this.catalogues.locations = response.data.locations.map((loc) => {return {text: loc, value: loc}})

          console.log("BarHorizontal: ", this.record, this.catalogues, response.data.locations.map((loc) => {return {text: loc, value: loc}}))

          this.chartData.BarHorizontal.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
        })
        .catch(error => {
          this.chartData.BarHorizontal.data = {
            labels: [],
            datasets: [
              {
                label: 'Toneladas de CO2e',
                data: []
              }
            ]
          }
          this.chartData.BarHorizontal.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
          this.error = true
          this.errors.emissionLocation = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEmissionOverTime() {
      this.loading = true
      this.axios.get(services.routes.emissionOverTime + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.chartData.Bar.data = response.data.data
          this.chartData.Bar.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
        })
        .catch(error => {
          this.chartData.Bar.data = {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            datasets: [
              {
                label: 'Toneladas de CO2e',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              }
            ]
          }
          this.chartData.Bar.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
          this.error = true
          this.errors.emissionOverTime = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getReductionMeasures() {
      this.loading = true
      this.axios.get(services.routes.reductionMeasures + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.reductionMeasures = response.data.data
        })
        .catch(error => {
          this.reductionMeasures = []
          this.error = true
          this.errors.reductionMeasures = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRole() {
      this.isAuditor = false
      let roles = this.$session.get('rol')
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].toLowerCase() === 'auditor') {
          this.isAuditor = true
        }
      }
    },
    getProfessionalSector() {
      this.isAgriculturalSector = false
      let companies = this.$session.get('companies')
      for (let i = 0; i < companies.length; i++) {
        if (companies[i].id == this.$store.state.general.company) {
          if (companies[i].sector_profesional.toLowerCase() === 'agrícola') {
            this.isAgriculturalSector = true
          }
        }
      }
    },
    async getData() {
      if (this.$store.state.general.company) {
        this.errors.kpis = false
        this.errors.hdc = false
        this.errors.emissionByCategory = false
        this.errors.emissionOverTime = false
        this.errors.reductionMeasures = false
        this.getKpis()
        await this.getHDC()
        this.getEmissionByCategory()
        this.getEmissionLocation()
        this.getEmissionOverTime()
        this.getReductionMeasures()
        this.getRole()
        this.getProfessionalSector()
      }
    },
    setReductionPromise(item){
      console.log(item)
    },
    showDialog(item){
      this.dialogs.selectItem=item
      this.dialogs.reductionMeasures = true
    },
    cancelNotification() {
      this.dialogs.selectItem.idCompany = this.$store.state.general.company
      let data = this.dialogs.selectItem
      this.axios.put(services.routes.reductionMeasureItems, { data }).then(res=>{
        // console.log(res.data.compromisos)
        this.getReductionMeasures()
      }).finally(()=>{
        this.selectReductionMeasure = null
        this.dialogs.reductionMeasures = false
        this.dialogs.selectItem = {
          idCompany:null,
          comprometido:false
        }
      })
    },
    getReportGeneral() {
      // let fechastorage = JSON.parse(localStorage.getItem('filters'));
      // if (fechastorage){
      //   this.record.periodo = fechastorage.periodo
      // }
      let companies = this.$session.get('companies')
      let selectedCompany = companies.find(x=>x.id==this.company)
      let fecha = new Date()
      const dia = String(fecha.getDate()).padStart(2, '0');
      const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
      const ano = fecha.getFullYear();
      const fechaFormateada = `${dia}_${mes}_${ano}`;
      console.log('record periodo 2',this.record.periodo)
      const source = _.get(this.chartData, 'Doughnut.data.labels', [])
      if (source.length == 1 && source[0] === 'Basura generada') {
        this.record.loading = true
        let params = {
          empresa: this.$store.state.general.company,
          tipo_periodo: this.record.tipoPeriodo,
          periodo: this.record.periodo,
          empresas: [this.$store.state.general.company],
          tipo_reporte: 'economia-circular'
        }
        console.log('record periodo 3',this.record.periodo)
        this.axios.get(services.routes.ec.report, { params })
          .then(response => {
            let data = response.data.data
            let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
            // console.log(this.company)
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year +selectedCompany.nombre+fechaFormateada+ '.pdf' , 'application/pdf')
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.setReport({ val: false })
            this.record.loading = false
          })
      }
      else {
        this.record.loading = true
        this.axios.get(services.routes.report + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
        '&periodo=' + this.record.periodo + '&tipo_reporte=general')
          .then(response => {
            let data = response.data.data
            let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_'+year+`_${selectedCompany.nombre}_${fechaFormateada}`+ '.pdf' , 'application/pdf')
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.setReport({ val: false })
            this.record.loading = false
          })
      }
    },
    getReportGarden(type) {
      let companies = this.$session.get('companies')
      let selectedCompany = companies.find(x=>x.id==this.company)
      let fecha = new Date()
      const dia = String(fecha.getDate()).padStart(2, '0');
      const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
      const ano = fecha.getFullYear();
      const fechaFormateada = `${dia}_${mes}_${ano}`;

      this.record.loading = true
      this.axios.get(services.routes.garden.report + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo + '&tipo_reporte=' + type)
        .then(response => {
          let data = response.data.data
          let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
          downloadjs('data:application/pdf;base64,' + data, 'Reporte_'+year+`_${selectedCompany.nombre}_${fechaFormateada}`+ '.pdf' , 'application/pdf')
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.setReport({ val: false })
          this.record.loading = false
        })
    },
    saveDateFilter(item){
      console.log(item)
      const dates = {
        periodo : item,
        tipoPeriodo : this.record.tipoPeriodo
      }
      localStorage.setItem('filters', JSON.stringify(dates));
      if(this.record.tipoPeriodo=='mensual'){
        console.log('ya llego',this.record.periodo)
        this.$refs.menuRecord.save(this.record.periodo)

      }
      //this.getData()
      this.setDateRecord()

    }
  }
}
</script>

<style scoped>
  .v-tooltip__content{
    font-size: 18px;
    font-weight: 500;
    z-index: 10;
  }
  .text-normal {
    text-transform: none !important;
  }
  .small {
    max-width: 600px;
  }
  .container.fill-height {
    justify-content: space-around;
    margin: auto;
  }
  .v-card {
    margin-top: 10px;
    background-color: rgb(227 227 227 / 28%)
  }
  #card-cards {
    /*margin-top: 10px;*/
  }
  .v-btn--icon.v-size--default{
    height: 0px;
  }
  .card {
    display: flex;
    justify-content: end;
  }
  .card2 {
    display: flex;
    justify-content: center;
  }
  .v-picker__actions {
    background-color: #f1f1f1;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 1400px) {
    .card-right {
      display: flex;
      justify-content: end;
      align-items: baseline;
    }
    .card-container {
      width: 50%;
      display: flex;
      justify-content: center;
    }
    #card-cards {
      width: 48%;
      margin-bottom: 20px;
    }
    .v-row {
      flex-wrap: wrap;
      justify-content: space-around !important;
    }
  }

  @media screen and (max-width: 700px) {
    .card-container {
      width: 100%;
      justify-content: center;
      row-gap: 1rem;
      column-gap: 1rem;
    }
    #card-cards {
      width: 100%;
      margin-bottom: 20px;
    }
    .v-row {
      flex-direction: column;
      justify-content: center !important;
    }
  }
</style>